var fn = [];
var env = [];
var bundle = [];

(function ($) {
    fn = $.fn;
    globalEnv = env.globalEnv = {};

    fn.addEnv = function (/* object */ opts) {
        return $.extend(globalEnv, opts);
    };

    fn.changeLocation = function (/* String */ url) {
        if (navigator.userAgent.indexOf("MSIE ") > -1) {
            window.location.href = url;
        } else {
            var parsedUrl = new URL(url, window.location);
            if (window.location.origin !== parsedUrl.origin) {
                window.location.href = url;
            } else if (window.location.href != parsedUrl.href) {
                history.pushState({}, '', url);
                var popStateEvent = null;
                try {
                    popStateEvent = new PopStateEvent('popstate', {url: url});
                } catch (e) {
                    popStateEvent = document.createEvent('PopStateEvent');
                    popStateEvent.initPopStateEvent('popstate', false, false, {url: url});
                }
                window.dispatchEvent(popStateEvent);
                $(document).trigger("url-change-event");
            }
        }
    };

    fn.openWindow = function (/* String */ url) {
        return (url != "" ? window.open(url) : false);
    };

    fn.getUrlParam = function (searchText, paramName) {
        var reParam = new RegExp('(?:[\?&]|&amp;)' + paramName + '=([^&]+)', 'i');
        var match = searchText.match(reParam);
        return (match && match.length > 1) ? match[1] : '';
    };

    fn.exists = function () {
        return ($(this).length > 0);
    };

    fn.jumpTo = function (el) {
        if (el.exists()) {
            $("html, body").animate({scrollTop: el.offset().top});
        }
    };

    fn.jumpToPosition = function (position) {
        var typePosition = $.type(position);
        if (typePosition === 'string' || typePosition === 'number') {
            $("html, body").animate({scrollTop: position});
        }
    }

    fn.getFirstMatchingClass = function (pattern) {
        var filter = pattern || false;
        var $classes = $(this).attr("class").split(" ");
        if (filter) {
            for (var i = 0; i < $classes.length; i++) {
                if ($classes[i].match(pattern)) {
                    return $classes[i];
                }
            }
        }
    };

    function renameEventsWithNamespace(events, namespaces) {
        var resultEvents;
        if (typeof (events) == "string") {
            resultEvents = events.split(" ").map(function (event) {
                return event + namespaces;
            }).join(" ");
        } else if (events === undefined) {
            return namespaces;
        } else {
            resultEvents = {};
            for (var key in events) {
                var newKey = key + namespaces;
                resultEvents[newKey] = events[key];
            }
        }
        return resultEvents;
    }

    /**
     *  jQuery.on() z namespace'ami .ingrwd, .page_[data-resource-id]
     */
    fn.onPage = function (events, selector, data, handler) {
        var namespaces = '.ingrwd' + '.page_' + $('#outlet-content').attr('data-resource-id');
        var resultEvents = renameEventsWithNamespace(events, namespaces);
        this.on(resultEvents, selector, data, handler);
        return this;
    };

    /**
     *  jQuery.off() z namespacem .page_[data-resource-id] dla #outlet-content
     */
    fn.offPage = function (events, selector, handler) {
        var namespaces = '.page_' + $('#outlet-content').attr('data-resource-id');
        var resultEvents = renameEventsWithNamespace(events, namespaces);
        this.off(resultEvents, selector, handler);
        return this;
    };

    /**
     *  jQuery.on() z namespace'ami .ingrwd, .page_[data-resource-id] dla #outlet-header
     */
    fn.onHeaderEvent = function (events, selector, data, handler) {
        var namespaces = '.ingrwd' + '.header_' + $('#outlet-header').attr('data-resource-id');
        var resultEvents = renameEventsWithNamespace(events, namespaces);
        this.on(resultEvents, selector, data, handler);
        return this;
    };

    /**
     *  jQuery.off() z namespacem .page_[data-resource-id] dla #outlet-header
     */
    fn.offHeaderEvent = function (events, selector, handler) {
        var namespaces = '.header_' + $('#outlet-header').attr('data-resource-id');
        var resultEvents = renameEventsWithNamespace(events, namespaces);
        this.off(resultEvents, selector, handler);
        return this;
    };

    /**
     *  jQuery.on() z namespace'ami .ingrwd, .page_[data-resource-id] dla #outlet-footer
     */
    fn.onFooterEvent = function (events, selector, data, handler) {
        var namespaces = '.ingrwd' + '.footer_' + $('#outlet-footer').attr('data-resource-id');
        var resultEvents = renameEventsWithNamespace(events, namespaces);
        this.on(resultEvents, selector, data, handler);
        return this;
    };

    /**
     *  jQuery.off() z namespacem .page_[data-resource-id] dla #outlet-footer
     */
    fn.offFooterEvent = function (events, selector, handler) {
        var namespaces = '.footer_' + $('#outlet-footer').attr('data-resource-id');
        var resultEvents = renameEventsWithNamespace(events, namespaces);
        this.off(resultEvents, selector, handler);
        return this;
    };

    /**
     *  jQuery.on() z namespace'ami .ingrwd, .template_[data-resource-id] dla #content-wrapper
     */
    fn.onTemplateEvent = function (events, selector, data, handler) {
        var namespaces = '.ingrwd' + '.template_' + $('#content-wrapper').attr('data-resource-id');
        var resultEvents = renameEventsWithNamespace(events, namespaces);
        this.on(resultEvents, selector, data, handler);
        return this;
    };

    /**
     *  jQuery.off() z namespacem .template_[data-resource-id] dla #content-wrapper
     */
    fn.offTemplateEvent = function (events, selector, handler) {
        var namespaces = '.template_' + $('#content-wrapper').attr('data-resource-id');
        var resultEvents = renameEventsWithNamespace(events, namespaces);
        this.off(resultEvents, selector, handler);
        return this;
    };

})(jQuery);

/*  Funkcja wykorzystywana przy skryptach,które zapinały sie na zdarzeniu "ready" w starej architekturze;
    została dodana, aby w dotychczasowych skryptach zrobić replace dla istniejącej składni zamiast kasowania kodu zapinającego się na zdarzeniu "ready" 
*/
function runFunction(f) {
    f();
}
